import {Component} from '@angular/core';
import {MatomoInjector} from 'ngx-matomo';

@Component({
  moduleId: module.id,
  selector: 'dmc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private matomoInjector: MatomoInjector
  ) {
    this.matomoInjector.init('https://analytics.it-for-girls.de/', 1);
  }

}

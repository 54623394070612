import {SharedActions, SharedActionTypes} from '../actions/shared.actions';


export interface State {
  embeddedIframeLink: string;
}

export const initialState: State = {
  embeddedIframeLink: null
};

export function reducer(state = initialState, action: SharedActions): State {
  switch (action.type) {
    case SharedActionTypes.LoadEmbeddedIframe:
      return {
        ...state,
        embeddedIframeLink: action.payload
      };
    default:
      return state;
  }
}


import {Component, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'dmc-youtubevideo',
  templateUrl: './youtubevideo.component.html',
  styleUrls: ['./youtubevideo.component.scss']
})
export class YoutubevideoComponent implements OnChanges {

  @Input()
  file: File;

  @Input()
  autoplay: boolean;

  src: string;

  constructor() { }

  ngOnChanges() {
    this.src = 'https://www.youtube-nocookie.com/embed/' + this.file + '?autoplay=' + (this.autoplay ? 1 : 0) + '&rel=0';
  }

}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {MatomoTracker} from 'ngx-matomo';


@Injectable()
export class RootEffects {


  @Effect({dispatch: false})
  routeTracking$ = this.actions$
    .pipe(
      ofType('ROUTER_NAVIGATION'),
      tap<RouterAction>(action => {
        this.matomoTracker.setCustomUrl(action.payload.routerState.url);
        this.matomoTracker.trackPageView();
      })
    );


  constructor(private actions$: Actions, private matomoTracker: MatomoTracker) {
  }
}


export class RouterAction {
  payload: {
    routerState: {
      url: string;
    }
  };
}

import { Injectable } from '@angular/core';

@Injectable()
export class ScrollToTopService {

  constructor() { }

  scrollToTop(): void {
    window.requestAnimationFrame(() => this.scrollTopSmoothly());
  }

  private scrollTopSmoothly(): void {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
}

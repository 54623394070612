import { Component } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'dmc-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  constructor() {
  }


}

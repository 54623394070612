import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Authenticate, User} from 'app/authentication/models/user';
import {environment} from 'environments/environment';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) {
  }

  login({username, password}: Authenticate): Observable<User> {
    return this.http.post<User>(`${environment.api}/api/signin`, {username, password});
  }
}

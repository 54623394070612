import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dmc-shell-without-footer',
  templateUrl: './shell-without-footer.component.html',
  styleUrls: ['./shell-without-footer.component.scss']
})
export class ShellWithoutFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dmc-exit-section',
  templateUrl: './exit-section.component.html',
  styleUrls: ['./exit-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExitSectionComponent implements OnInit {

  @Input() class: string;

  constructor() { }

  ngOnInit() {
  }

}

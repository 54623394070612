import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './containers/home.component';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../shared/guards/auth.guard';
import { StoreModule } from '@ngrx/store';
import * as fromHome from './reducers/home.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HomeEffects } from './effects/home.effects';
import {AvencyEmbeddedIframeComponent} from './components/avency-embedded-iframe/avency-embedded-iframe.component';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    StoreModule.forFeature('home', fromHome.reducer),
    EffectsModule.forFeature([HomeEffects])
  ],
  declarations: [HomeComponent, AvencyEmbeddedIframeComponent],
  providers: [AuthGuard]
})
export class HomeModule { }

import {
  createSelector,
  createFeatureSelector, ActionReducerMap
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from './auth.reducer';

export interface AuthState {
  auth: fromAuth.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState> = {
  auth: fromAuth.reducer,
};

export const selectState = createFeatureSelector<AuthState>('auth');

export const selectAuthState = createSelector(
  selectState,
  (state: AuthState) => state.auth
);

export const getLoggedIn = createSelector(
  selectAuthState,
  fromAuth.getLoggedIn
);
export const getUser = createSelector(
  selectAuthState,
  fromAuth.getUser
);
export const getError = createSelector(
  selectAuthState,
  fromAuth.getError
);

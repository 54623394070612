import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Spiel} from 'app/shared/model/spiel.interface';
import {InstructionComponent} from 'app/shared/components/instruction/instruction.component';
import {MatDialog} from '@angular/material';
import {LoadGame} from '../../actions/shared.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'dmc-game-tile',
  templateUrl: './game-tile.component.html',
  styleUrls: ['./game-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameTileComponent implements OnInit {

  constructor(private dialog: MatDialog, private store: Store<any>) { }

  @Input() game: Spiel;

  ngOnInit() {
  }

  openVideo(file: string) {
    this.dialog.open(InstructionComponent, {data: file});
  }

  navigateToGame(game: Spiel) {
    this.store.dispatch(new LoadGame(game));
  }
}


import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {fromEvent} from 'rxjs/internal/observable///fromEvent';
import {AvencyEmbeddedIframeComponent} from '../components/avency-embedded-iframe/avency-embedded-iframe.component';
import {LeaveHome} from '../actions/home.actions';
import {Store} from '@ngrx/store';
import {State} from '../reducers/home.reducer';
import {getHeight} from '../reducers';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  moduleId: module.id,
  selector: 'dmc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  avencyUrl: string;
  height$: Observable<number>;
  @ViewChild(AvencyEmbeddedIframeComponent) iFrame: AvencyEmbeddedIframeComponent;

  constructor(private route: ActivatedRoute, private router: Router, private store: Store<State>, private ngZone: NgZone) {
    this.height$ = store.select(getHeight);
  }

  ngOnInit(): void {
    this.route.data.pipe(
      map((data: Data) => Object.values(data)),
      map(avencyUrl => avencyUrl.pop())
    ).subscribe(url => {
      this.avencyUrl = url;
    });
  }

  iFrameLoaded(): void {

    if (this.iFrame && this.iFrame.iFrameContent) {
      const iframeContent = this.iFrame.iFrameContent;
      if (iframeContent) {

        const questionmark1_popup_btn = iframeContent.querySelector('#itquiz0 > div.popover-body > a');
        const questionmark2_popup_btn = iframeContent.querySelector('#itquiz1 > div.popover-body > a');
        fromEvent([questionmark1_popup_btn, questionmark2_popup_btn], 'click').subscribe(() => this.navigateTo('/quiz'));

        const kompass1_popup_btn = iframeContent.querySelector('#berufskompass0 > div.popover-body > a');
        const kompass2_popup_btn = iframeContent.querySelector('#berufskompass1 > div.popover-body > a');
        fromEvent([kompass1_popup_btn, kompass2_popup_btn], 'click').subscribe(() => this.navigateTo('/berufskompass'));

        const betrieb_service_popup_btn = iframeContent.querySelector('#betriebservice0 > div.popover-body > a');
        fromEvent(betrieb_service_popup_btn, 'click').subscribe(() => this.navigateTo('/betrieb-und-service'));

        const startup_popup_btn = iframeContent.querySelector('#startup0 > div.popover-body > a');
        fromEvent(startup_popup_btn, 'click').subscribe(() => this.navigateTo('/start-up'));

        const krankenwagen_popup_btn = iframeContent.querySelector('#krankenwagen0 > div.popover-body > a');
        fromEvent(krankenwagen_popup_btn, 'click').subscribe(() => this.navigateTo('medizin-it'));

        const wegweiser_popup_btn = iframeContent.querySelector('#wegweiser0 > div.popover-body > a');
        fromEvent(wegweiser_popup_btn, 'click').subscribe(() => this.navigateTo('/leiten-fuehren-managen'));

        const vermarkten_verkaufen_popup_btn = iframeContent.querySelector('#vermarktenundvertrieb0 > div.popover-body > a');
        fromEvent(vermarkten_verkaufen_popup_btn, 'click').subscribe(() => this.navigateTo('/marketing-und-vertrieb'));

        const it_security_popup_btn = iframeContent.querySelector('#itsecurity0 > div.popover-body > a');
        fromEvent(it_security_popup_btn, 'click').subscribe(() => this.navigateTo('/it-security'));

        const entwicklung_design_popup_btn = iframeContent.querySelector('#entwicklungdesign0 > div.popover-body > a');
        fromEvent(entwicklung_design_popup_btn, 'click').subscribe(() => this.navigateTo('/design-und-entwicklung'));

        const autonomes_fahren_popup_btn = iframeContent.querySelector('#autonomesfahren0 > div.popover-body > a');
        fromEvent(autonomes_fahren_popup_btn, 'click').subscribe(() => this.navigateTo('/autonomes-fahren'));

        const greenit_popup_btn = iframeContent.querySelector('#greenit0 > div.popover-body > a');
        fromEvent(greenit_popup_btn, 'click').subscribe(() => this.navigateTo('/green-it'));

        const unterstuetzen_beraten_popup_btn = iframeContent.querySelector('#unterstuetzenberaten0 > div.popover-body > a');
        fromEvent(unterstuetzen_beraten_popup_btn, 'click').subscribe(() => this.navigateTo('/unterstuetzen-und-beraten'));

        const cloudcomputing_popup_btn = iframeContent.querySelector('#cloudcomputing0 > div.popover-body > a');
        fromEvent(cloudcomputing_popup_btn, 'click').subscribe(() => this.navigateTo('/cloud-computing'));

        const bildung_forschung_popup_btn = iframeContent.querySelector('#bildungforschung0 > div.popover-body > a');
        fromEvent(bildung_forschung_popup_btn, 'click').subscribe(() => this.navigateTo('/bildung-und-forschung'));

        const analyse_datenmanagement_popup_btn = iframeContent.querySelector('#analysedatenmanagement0 > div.popover-body > a');
        fromEvent(analyse_datenmanagement_popup_btn, 'click').subscribe(() => this.navigateTo('/analyse-und-datenmanagement'));


        const andere_berufsinformation_btn = iframeContent.querySelector('#berufsplattformen > article > div.card-body > div > a');
        fromEvent(andere_berufsinformation_btn, 'click').subscribe(() => {
          this.navigateTo('/andere-berufsinformationsplattform');
        });

        const andere_studiengaenge_btn = iframeContent.querySelector('#studiengaenge > article > div.card-body > div > a');
        fromEvent(andere_studiengaenge_btn, 'click').subscribe(() => {
          this.navigateTo('/studiengaenge');
        });

        const datenschutz_btn = iframeContent.querySelector('#datenschutzbutton');
        fromEvent(datenschutz_btn, 'click').subscribe(() => {
          this.navigateTo('/datenschutz');
        });

        const impressum_btn = iframeContent.querySelector('#impressumbutton');
        fromEvent(impressum_btn, 'click').subscribe(() => {
          this.navigateTo('/impressum');
        });


      }
    }
  }

  navigateTo(url: string): void {
    this.store.dispatch(new LeaveHome(this.iFrame.getHeight()));
    this.ngZone.run(() => {
      this.router.navigate([url]);
    });
  }

}

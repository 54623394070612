import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'dmc-avency-embedded-iframe',
  templateUrl: './avency-embedded-iframe.component.html',
  styleUrls: ['./avency-embedded-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvencyEmbeddedIframeComponent {

  @Input() url: string;
  @Input() height: number;
  @Output() iFrameLoaded = new EventEmitter();
  @ViewChild('iframe') iFrame: ElementRef;
  iFrameContent;


  iFrameLoadedEvent() {
    this.iFrameContent = this.iFrame.nativeElement.contentDocument;
    this.iFrameLoaded.emit();
    this.iFrame.nativeElement.contentWindow.postMessage(this.height, '*');
  }

  getHeight(): number {
    const iframe = this.iFrame.nativeElement.contentWindow;
    return iframe.iOS ? iframe.annaposition * -1 : this.iFrame.nativeElement.contentWindow.pageYOffset;
  }


}

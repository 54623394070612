// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  cms: 'https://cms.it-for-girls.de',
  api: 'http://0.0.0.0:3000',
  terminal: 'http://0.0.0.0:4202',
  etl: 'http://0.0.0.0:4201',
  tsp: 'http://0.0.0.0:4204',
  scrum: 'http://0.0.0.0:4203',
  tickets: 'http://0.0.0.0:4205'
};

import {Component, Input, OnInit} from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'dmc-speech-bubble-popup',
  templateUrl: './speech-bubble-popup.component.html',
  styleUrls: ['./speech-bubble-popup.component.scss']
})
export class SpeechBubblePopupComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  fullImagePath: string;

  @Input()
  exampleHeadline: string;

  @Input()
  topSpeechBubbleText: string;

  @Input()
  bottomSpeechBubbleText: string;

  fullImagePathAnna = 'assets/berufskompass/images/AnnaKopf2.svg';


  constructor() {
  }

  ngOnInit() {
    swal({
      title: this.title,
      width: '95vw',
      background: '#d3e4ea',
      html: '<div>' +
      '        <div class="speech-bubble-description">' +
      '          <div class="speech-bubble-top">' +
      '            <p class="speech-bubble-p">' + this.topSpeechBubbleText + '</p>' +
      '          </div>' +
      '          <img class="speech-bubble-anna" src="' + this.fullImagePathAnna + '">' +
      '        </div>' +
      '        <div class="speech-bubble-example">\n' +
      '          <h5 class="speech-bubble-headline-h5" >' + this.exampleHeadline + '</h5>' +
      '          <img src="' + this.fullImagePath + '" class="speech-bubble-example-image">' +
      '          <div class="speech-bubble-buttom">\n' +
      '            <p class="speech-bubble-p">' +
      '                ' + this.bottomSpeechBubbleText +
      '            </p>' +
      '          </div>' +
      '        </div>' +
      '      </div>',
      showCloseButton: true
    });
  }

}

import { Action } from '@ngrx/store';

export enum HomeActionTypes {
  LoadHomes = '[Home] Load Homes',
  LeaveToTopicOrHouse = '[Home] Leave Home'
}

export class Home implements Action {
  readonly type = HomeActionTypes.LoadHomes;
}

export class LeaveHome implements Action {
  readonly type = HomeActionTypes.LeaveToTopicOrHouse;
  constructor(public payload: number) {}
}

export type HomeActions = Home | LeaveHome;

import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationModule} from './authentication/authentication.module';
import {HomeModule} from './home/home.module';
import {SharedModule} from './shared/shared.module';
import {AuthGuard} from './shared/guards/auth.guard';
import {environment} from 'environments/environment';

import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {CustomSerializer, metaReducers, reducers} from 'app/reducers';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import 'hammerjs';
import 'mousetrap';
import {ModalGalleryModule} from '@ks89/angular-modal-gallery';
import {CookieLawModule} from 'angular2-cookie-law';
import {MatomoModule} from 'ngx-matomo';
import {RootEffects} from './root.effects';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HomeModule,
    CookieLawModule,
    AuthenticationModule,
    SharedModule,
    ModalGalleryModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument(),
    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router', // name of reducer key
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    MatomoModule

  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    AuthGuard,
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: RouterStateSerializer, useClass: CustomSerializer}
  ]
})
export class AppModule {
}

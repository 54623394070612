import {Injectable} from '@angular/core';
import {CanActivate, CanLoad, Route, Router} from '@angular/router';
import {AuthService} from 'app/authentication/services/auth.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '../../authentication/reducers/index';
import * as AuthActions from '../../authentication/actions/auth.actions';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService,
              private router: Router,
              private store: Store<fromAuth.State>) {
  }

  canActivate(): Observable<boolean> {
    return this.checkAuthenticationAndRedirect();
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkAuthenticationAndRedirect();
  }

  checkAuthenticationAndRedirect(): Observable<boolean> {
    return this.store
      .pipe(
        select(fromAuth.getLoggedIn),
        map(authenticated => {
          if (!authenticated) {
            this.store.dispatch(new AuthActions.LoginRedirect());
            return false;
          }

          return true;
        }),
        take(1)
      );
  }
}

import {Data, Params, RouterStateSnapshot} from '@angular/router';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {routerReducer, RouterReducerState, RouterStateSerializer} from '@ngrx/router-store';
import {environment} from 'environments/environment';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import {storeFreeze} from 'ngrx-store-freeze';
import {localStorageSync} from 'ngrx-store-localstorage';


export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {url, root: {queryParams}} = routerState;
    const {params} = route;
    const data = route.data;

    // Only return an object including the URL, params, query params and data
    // instead of the entire snapshot
    return {url, params, queryParams, data};
  }
}


/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: RouterReducerState<RouterStateUrl>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  router: routerReducer
};
/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ?
  [logger, storeFreeze, localStorageSyncReducer] :
  [localStorageSyncReducer];

// console.log all actions and current state
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(
    {
      keys: [
        'home',
        'auth',
        'itGames',
        'bpmn',
        'design',
        'shared',
        'quiz',
        'berufsfeldthema',
        'andere-berufsinformationensplattformen',
        'studiengangshaeuser'
      ],
      rehydrate: true
    })(reducer);
}

import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getEmbeddedIframeLink} from '../../reducers/selectors';
import {Location} from '@angular/common';

@Component({
  selector: 'dmc-embedded-iframe',
  templateUrl: './embedded-iframe.component.html',
  styleUrls: ['./embedded-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbeddedIFrameComponent implements OnInit {

  url: string;
  @Output() iFrameLoaded = new EventEmitter();
  @ViewChild('iframe') iFrame: ElementRef;
  iFrameContent;

  constructor(private store: Store<any>, private location: Location) {
    store.pipe(select(getEmbeddedIframeLink)).subscribe(url => {
      this.url = url;
    });
  }

  ngOnInit(): void {

  }

  iFrameLoadedEvent() {
    this.iFrameContent = this.iFrame.nativeElement.contentDocument;
    this.iFrameLoaded.emit();
  }

  navigateBack(): void {
    this.location.back();
  }

}

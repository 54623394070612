import {Action} from '@ngrx/store';
import {Spiel} from '../model/spiel.interface';

export enum SharedActionTypes {
  ScrollElementIntoView = '[Shared] Scroll Element Into View',
  ScrollToTop = '[Shared] Scroll To Top',
  LoadEmbeddedIframe = '[Shared] Load EmbeddedIframe',
  LoadInternalGame = '[Shared] Load Internalgame',
  LoadGame = '[Shared] Load Spiel'
}

export class ScrollElementIntoView implements Action {
  readonly type = SharedActionTypes.ScrollElementIntoView;

  constructor(public payload: string) {
  }
}


export class LoadEmbeddedIframe implements Action {
  readonly type = SharedActionTypes.LoadEmbeddedIframe;

  constructor(public payload: string) {
  }
}

export class LoadInternalGame implements Action {
  readonly type = SharedActionTypes.LoadInternalGame;

  constructor(public payload: string) {
  }
}

export class LoadGame implements Action {
  readonly type = SharedActionTypes.LoadGame;

  constructor(public payload: Spiel) {
  }
}



export class ScrollToTop implements Action {
  readonly type = SharedActionTypes.ScrollToTop;
}

export type SharedActions = ScrollToTop | ScrollElementIntoView | LoadEmbeddedIframe | LoadGame | LoadInternalGame;

import { Injectable } from '@angular/core';

@Injectable()
export class ScrollElementIntoViewService {

  constructor() { }

  scrollElementIntoView(node: string): void {
    const elementToScrollTo = document.querySelector(node);
    elementToScrollTo.scrollIntoView();
  }

}

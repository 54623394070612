import { Component, Input, OnInit } from '@angular/core';
import { Authenticate } from 'app/authentication/models/user';
import * as AuthActions from 'app/authentication/actions/auth.actions';
import { Observable } from 'rxjs';
import * as fromAuth from 'app/authentication/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'dmc-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {


  @Input() error$: Observable<string>;

  model = {
    name: '',
    password: ''
  };

  constructor(private store: Store<fromAuth.State>) {
  }

  ngOnInit() {
  }

  async login($event: Authenticate): Promise<void> {
    this.store.dispatch(new AuthActions.Login({username: this.model.name, password: this.model.password}));
  }

}

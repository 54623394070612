import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '../reducers/index';
import { Observable } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'dmc-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  error$: Observable<string>;

  constructor(private store: Store<fromAuth.State>) {
  }

  ngOnInit(): void {
   this.error$ = this.store.pipe(select(fromAuth.getError));
  }
}

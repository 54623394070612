import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {EmbeddedIFrameComponent} from 'app/shared/components/embedded-iframe/embedded-iframe.component';
import {ShellComponent} from 'app/shared/components/shell/shell.component';
import {ShellWithoutFooterComponent} from './shared/components/shell-without-footer/shell-without-footer.component';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'betrieb-und-service',
        loadChildren: 'app/betrieb-und-service/betrieb-und-service.module#BetriebUndServiceModule'
      },
      {
        path: 'design-und-entwicklung',
        loadChildren: 'app/design-entwicklung/design-entwicklung.module#DesignEntwicklungModule'
      },
      {
        path: 'analyse-und-datenmanagement',
        loadChildren: 'app/analyse-datenmanagement/analyse-datenmanagement.module#AnalyseDatenmanagementModule'
      },
      {
        path: 'marketing-und-vertrieb',
        loadChildren: 'app/marketing-vertrieb/marketing-vertrieb.module#MarketingVertriebModule'
      },
      {
        path: 'it-security',
        loadChildren: 'app/it-security/it-security.module#ItSecurityModule'
      },
      {
        path: 'unterstuetzen-und-beraten',
        loadChildren: 'app/unterstuetzen-beraten/unterstuetzen-beraten.module#UnterstuetzenBeratenModule'
      },
      {
        path: 'leiten-fuehren-managen',
        loadChildren: 'app/leiten-fuehren-managen/leiten-fuehren-managen.module#LeitenFuehrenManagenModule'
      },
      {
        path: 'green-it',
        loadChildren: 'app/green-it/green-it.module#GreenItModule'
      },
      {
        path: 'bildung-und-forschung',
        loadChildren: 'app/bildung-forschung/bildung-forschung.module#BildungForschungModule'
      },
      {
        path: 'start-up',
        loadChildren: 'app/start-up/start-up.module#StartUpModule'
      },
      {
        path: 'cloud-computing',
        loadChildren: 'app/cloud-computing/cloud-computing.module#CloudComputingModule'
      },
      {
        path: 'medizin-it',
        loadChildren: 'app/medizin-it/medizin-it.module#MedizinItModule'
      },
      {
        path: 'autonomes-fahren',
        loadChildren: 'app/autonomes-fahren/autonomes-fahren.module#AutonomesFahrenModule'
      },
      {
        path: 'it-games',
        loadChildren: 'app/it-games/it-games.module#ItGamesModule'
      },
      {
        path: 'games/embedded',
        component: EmbeddedIFrameComponent
      },
      {
        path: 'games/bpmn',
        loadChildren: 'app/bpmn/bpmn.module#BpmnModule'
      },
      {
        path: 'games/design',
        loadChildren: 'app/design/design.module#DesignModule'
      },
      {
        path: 'quiz',
        loadChildren: 'app/quiz/quiz.module#QuizModule'
      },
      {
        path: 'berufskompass',
        loadChildren: 'app/berufskompass/berufskompass.module#BerufskompassModule'
      },
      {
        path: 'impressum',
        loadChildren: 'app/impressum/impressum.module#ImpressumModule',
      },
      {
        path: 'datenschutz',
        loadChildren: 'app/datenschutz/datenschutz.module#DatenschutzModule',
      },
      {
        path: 'andere-berufsinformationsplattform',
        loadChildren: 'app/andere-berufsinformationsplattformen/andere-berufsinformationsplattformen.module#AndereBerufsinformationsplattformenModule'
      },
      {
        path: 'studiengaenge',
        loadChildren: 'app/studiengangshaeuser/studiengangshaus.module#StudiengangshausModule'
      }

    ]
  },
  {
    path: 'auth',
    loadChildren: 'app/authentication/authentication.module#AuthenticationModule',
  },
  {
    path: 'home',
    component: ShellWithoutFooterComponent,
    children: [{
      path: '',
      loadChildren: 'app/home/home.module#HomeModule',
      data: ['../assets/index.html']
    }]
  },
  {
    path: '**', component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { HomeActions, HomeActionTypes } from '../actions/home.actions';

export interface State {
  home: {};
  position: number;
}

export const initialState: State = {
  home: {},
  position: 0
};


export function reducer(state = initialState, action: HomeActions): State {
  switch (action.type) {

    case HomeActionTypes.LoadHomes:
      return state;

    case HomeActionTypes.LeaveToTopicOrHouse: {
      return {
        ...state,
        position: action.payload
      };
    }
    default:
      return state;
  }
}


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {AuthGuard} from './guards/auth.guard';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {ButtonComponent} from './components/button/button.component';
import {TaskBoxComponent} from './components/task-box/task-box.component';
import {BrowserDetectionService} from './services/browser-detection.service';
import {FooterComponent} from './components/footer/footer.component';
import {EmbeddedIFrameComponent} from './components/embedded-iframe/embedded-iframe.component';
import {RouterModule} from '@angular/router';
import {ShellComponent} from './components/shell/shell.component';
import {ScrollElementIntoViewService} from 'app/shared/services/scroll-element-into-view.service';
import {EffectsModule} from '@ngrx/effects';
import {SharedEffects} from './effects/shared.effects';
import {SafePipe} from './pipes/safe.pipe';
import {ExitSectionComponent} from './components/exit-section/exit-section.component';
import {StoreModule} from '@ngrx/store';
import * as fromShared from './reducers/shared.reducer';
import {GameTileComponent} from './components/game-tile/game-tile.component';
import {InstructionComponent} from './components/instruction/instruction.component';
import {MatDialogModule} from '@angular/material';
import {YoutubevideoComponent} from './components/youtubevideo/youtubevideo.component';
import {ScrollToTopService} from './services/scroll-to-top.service';
import {ShellWithoutFooterComponent} from './components/shell-without-footer/shell-without-footer.component';
import {SpeechBubblePopupComponent} from './components/speech-bubble-popup/speech-bubble-popup.component';
/*tslint:disable*/

/*tslint:enable*/

// Declarations Block which should be exported
const fromDeclarations = [
  NotFoundComponent,
  ToolbarComponent,
  ButtonComponent,
  TaskBoxComponent,
  FooterComponent,
  EmbeddedIFrameComponent,
  ShellComponent,
  SafePipe,
  ExitSectionComponent,
  InstructionComponent,
  GameTileComponent,
  YoutubevideoComponent,
  ShellWithoutFooterComponent,
  SpeechBubblePopupComponent
];

@NgModule({
  entryComponents: [
    InstructionComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    MatDialogModule,
    RouterModule,
    StoreModule.forFeature('shared', fromShared.reducer),
    EffectsModule.forFeature([SharedEffects])
  ],
  declarations: [...fromDeclarations],
  providers: [
    AuthGuard,
    ScrollElementIntoViewService,
    ScrollToTopService,
    BrowserDetectionService
  ],
  exports: [...fromDeclarations]
})
export class SharedModule {
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ScrollElementIntoViewService} from 'app/shared/services/scroll-element-into-view.service';
import {map, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Router} from '@angular/router';
import {ScrollToTopService} from 'app/shared/services/scroll-to-top.service';
import {
  LoadEmbeddedIframe,
  LoadGame,
  LoadInternalGame,
  ScrollElementIntoView,
  ScrollToTop,
  SharedActions,
  SharedActionTypes
} from '../actions/shared.actions';
import {ROUTER_NAVIGATION} from '@ngrx/router-store/src/router_store_module';

@Injectable()
export class SharedEffects {

  @Effect({dispatch: false})
  scrollElementIntoView$ = this.actions$
    .pipe(
      ofType<ScrollElementIntoView>(SharedActionTypes.ScrollElementIntoView),
      map(action => action.payload),
      tap((elementId: string) => {
        of(this.router.navigate(['/']))
          .pipe(
            tap(() => {
              this.scrollElementIntoViewService
                .scrollElementIntoView(elementId);
            })
          );
      })
    );

  @Effect()
  routerNavigation$ = this.actions$
    .pipe(
      ofType('ROUTER_NAVIGATION'),
      map(() => new ScrollToTop())
    );

  @Effect({dispatch: false})
  scrollToTop$ = this.actions$
    .pipe(
      ofType<ScrollToTop>(SharedActionTypes.ScrollToTop),
      tap(() => {
        this.scrollToTopService.scrollToTop();
      })
    );

  @Effect()
  loadGame$ = this.actions$
    .pipe(
      ofType<LoadGame>(SharedActionTypes.LoadGame),
      map<LoadGame, SharedActions>(action => {
        if (action.payload.external) {
          return new LoadEmbeddedIframe(action.payload.gameLink);
        } else {
          return new LoadInternalGame(action.payload.gameLink);
        }
      })
    );

  @Effect({dispatch: false})
  loadEmbeddedIframe$ = this.actions$
    .pipe(
      ofType<LoadEmbeddedIframe>(SharedActionTypes.LoadEmbeddedIframe),
      tap(action => {
        of(this.router.navigate(['games/embedded']));
      })
    );


  @Effect({dispatch: false})
  loadInternalGame$ = this.actions$
    .pipe(
      ofType<LoadInternalGame>(SharedActionTypes.LoadInternalGame),
      tap(action => {
        of(this.router.navigate([action.payload]));
      })
    );


  constructor(private actions$: Actions,
              private scrollElementIntoViewService: ScrollElementIntoViewService,
              private scrollToTopService: ScrollToTopService,
              private router: Router) {
  }
}

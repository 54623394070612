import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  AuthActionTypes,
  Login,
  LoginFailure,
  LoginRedirect,
  LoginSuccess,
} from '../actions/auth.actions';
import { Authenticate } from '../models/user';
import { AuthService } from './../services/auth.service';

@Injectable()
export class AuthEffects {

  constructor(private actions$: Actions,
              private authService: AuthService,
              private router: Router) {
  }

  @Effect()
  login$ = this.actions$
    .pipe(
      ofType<Login>(AuthActionTypes.Login),
      map(action => action.payload),
      switchMap((auth: Authenticate) =>
        this.authService
          .login(auth)
          .pipe(
            map(user => new LoginSuccess({user})),
            catchError(error => of(new LoginFailure(error.status)))
          )
      )
    );

  @Effect({dispatch: false})
  loginSuccess$ = this.actions$
    .pipe(
      ofType<LoginSuccess>(AuthActionTypes.LoginSuccess),
      tap(() => {
        this.router.navigate(['/']);
      })
    );

  @Effect({dispatch: false})
  loginRedirect$ = this.actions$
    .pipe(
      ofType<LoginRedirect>(AuthActionTypes.LoginRedirect, AuthActionTypes.Logout),
      tap(() => {
        this.router.navigate(['/auth']);
      })
    );
}

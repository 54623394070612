import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'dmc-task-box',
  templateUrl: './task-box.component.html',
  styleUrls: ['./task-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskBoxComponent implements OnInit {

  @Input() task;


  constructor() {
  }

  ngOnInit() {
  }

}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'dmc-games-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public file: string) {
  }
}

import { Injectable } from '@angular/core';
import * as browser from 'bowser';

@Injectable()
export class BrowserDetectionService {

  constructor() {
  }

  isMobile(): boolean {
    return browser.mobile;
  }

  isTablet(): boolean {
    return browser.tablet;
  }
}

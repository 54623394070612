import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromHome from './home.reducer';


export const getHomeState = createFeatureSelector<fromHome.State>('home');

export const getHeight = createSelector(
  getHomeState,
  (state: fromHome.State) => state.position
);

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromShared from './shared.reducer';


export const getSharedState = createFeatureSelector<fromShared.State>('shared');


export const getEmbeddedIframeLink = createSelector(
  getSharedState,
  (state: fromShared.State) => state.embeddedIframeLink
);
